import React, { useState } from "react"
import InfoIcon from "assets/svg/info.svg"
import { Container, Content, Message } from "./AffirmTeaser.styles"
import { usdFmt } from "utils/priceUtils"
import { siteConstants } from "constants/site"
import { AffirmModalSanity } from "components/UI/Affirm/AffirmModal.sanity"

type Props = {
  price: number
}

export const AffirmTeaser = ({ price }: Props) => {
  const [modalShown, setModalShown] = useState(false)
  const formattedPrice = usdFmt(price / 4)
  const showModal = () => setModalShown(true)
  const hideModal = () => setModalShown(false)
  return (
    <>
      <Container onClick={showModal}>
        <InfoIcon />
        <Content>
          {price > 50 ? (
            <Message>
              <span>4 interest-free payments of {formattedPrice} with </span>
              <img width={46} src={siteConstants.affirmLogo} alt="Affirm" />
            </Message>
          ) : (
            <Message>
              <img width={46} src={siteConstants.affirmLogo} alt="Affirm" />
              <span> is available on orders $50+</span>
            </Message>
          )}
        </Content>
      </Container>
      {modalShown && <AffirmModalSanity onClose={hideModal} />}
    </>
  )
}
