import React, { useContext, useCallback } from "react"
import { ProductContext } from "contexts/ProductContext"
import { SelectedOptionsKey } from "typings/modules"
import styled from "styled-components"
import { Dropdown } from "components/UI/Dropdown/Dropdown"
import { typography } from "styles"
import { ColorOptionUI } from "components/ColorOptions/ColorOptionUI"
import { getSelectedOptions } from "utils/productUtils"
import { VariantSettingsContext } from "contexts/VariantSettingsContext"
import { DropdownItem } from "components/UI/Dropdown/Dropdown.styles"

type Props = {
  optionType: SelectedOptionsKey
  barOpen?: boolean
}

const SelectedValue = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 8px;
  ${typography.bodySmall};
  color: ${({ theme }) => theme.global?.dropdown?.primaryText?.opacityHex};
`

const ListText = styled.span`
  color: ${({ theme }) => theme.global?.dropdown?.primaryText?.opacityHex};
  ${typography.bodySmall};
  margin-left: 8px;
`

const ListContainer = styled.div`
  max-height: 240px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--slider-bar-active);
    border-radius: 3px;
  }
`

export const VariantDropup = ({ optionType, barOpen }: Props) => {
  const {
    selectedVariant,
    selectableOptions,
    updateSelectedVariant,
  } = useContext(ProductContext)
  const { shouldUseSwatches } = useContext(VariantSettingsContext)
  const isColorSet = shouldUseSwatches(optionType)
  const selectedOptions = getSelectedOptions(selectedVariant)

  const onClick = useCallback(
    option => () => {
      updateSelectedVariant(selectedVariant, { [optionType]: option })
    },
    [optionType, selectedVariant]
  )
  return (
    <Dropdown
      position="top right"
      forceClose={!barOpen}
      renderButton={
        <SelectedValue>
          {isColorSet && (
            <ColorOptionUI
              selected
              swatch={selectedVariant?.swatch}
              disabled={!selectedVariant?.availableForSale}
            />
          )}
          {selectedOptions?.[optionType]?.value}
        </SelectedValue>
      }
      renderList={
        <ListContainer>
          {selectableOptions?.[optionType]?.map(({ option, optionVariant }) => (
            <DropdownItem
              key={`${optionType}-${option.value}`}
              onClick={onClick(option)}
            >
              {isColorSet && (
                <ColorOptionUI
                  selected={selectedOptions?.[optionType]?.id === option?.id}
                  swatch={optionVariant?.swatch}
                  disabled={!optionVariant?.availableForSale}
                />
              )}
              <ListText>{option.value}</ListText>
            </DropdownItem>
          ))}
        </ListContainer>
      }
    />
  )
}
