export const scrollToElement = (selector: string, offset = 0) => {
  const element = document.querySelector(selector)
  if (!element || typeof window === "undefined") {
    return
  }
  const bodyY = document.body.getBoundingClientRect()?.top
  const y = element?.getBoundingClientRect().top - bodyY + offset
  window?.scrollTo({
    top: y,
    behavior: "smooth",
  })
}
