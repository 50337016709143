import React from "react"
import { graphql } from "gatsby"
import { ProductProvider } from "contexts/ProductContext"
import { ProductSchema } from "components/UI/ProductSchema"
import { ProductStateManager } from "components/Products/ProductStateManager"
import { PDPAboveTheFold } from "components/Products/PDPAboveTheFold"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { App } from "components/App/App"
import { renderBlock } from "components/Helpers/ComponentRenderer"
import { setTheme } from "helpers/Theme"
import { ModeType } from "typings/modules"
import { cleanUrlSlashes } from "utils/stringUtils"
import { mergeReviews } from "utils/reviewUtils"
import { Query } from "typings/graphql"

type ProductTemplateProps = {
  data: Query
}

const ProductTemplate = ({ data }: ProductTemplateProps) => {
  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )
  const productPathPrefix = useProductPathPrefix()
  const item = data.allSanityProduct.nodes[0]
  const product = data.allShopifyProduct.nodes[0]
  const blocks = item.blocks
  const mode = item.navTheme as ModeType
  const hasAReviewComponent = blocks.some(
    b => b?.__typename === "SanityYotpoReviewList"
  )

  const seoData = {
    seo: item?.seo,
    path: cleanUrlSlashes(`${productPathPrefix}${item?.path?.current}`),
  }

  const yotpoProduct = data.allYotpoProduct.nodes[0]
  const mergedReviews = mergeReviews(data.allYotpoProduct.nodes)

  return (
    <ProductProvider review={mergedReviews || yotpoProduct}>
      <App theme={theme} layout="product" seo={seoData} mode={mode}>
        <ProductStateManager staticData={data} />
        <ProductSchema
          product={product}
          productPathPrefix={productPathPrefix}
          yotpoProduct={yotpoProduct}
        />
        <PDPAboveTheFold
          staticData={data}
          mode={mode}
          hasAReviewComponent={hasAReviewComponent}
        />
        {blocks?.map((block, index) => renderBlock(block, index))}
      </App>
    </ProductProvider>
  )
}

export default ProductTemplate

export const pageQuery = graphql`
  query ProductByHandle($id: String!, $yotpoIds: [String!]!, $handle: String!) {
    allYotpoProduct(filter: { productId: { in: $yotpoIds } }) {
      nodes {
        productId
        bottomline {
          totalReview
          averageScore
        }
        reviews {
          id
          title
          content
          score
          sentiment
          votesUp
          votesDown
          createdAt
          user {
            displayName
            userType
          }
          imagesData {
            id
            thumbUrl
            originalUrl
          }
        }
      }
    }
    allShopifyProduct(filter: { handle: { eq: $handle } }) {
      nodes {
        id
        shopifyId: storefrontId
        handle
        description
        title
        images {
          altText
          originalSrc
        }
        variants {
          availableForSale
          sku
          id
          shopifyId
          price
          compareAtPrice
          title
          selectedOptions {
            name
            value
          }
        }
      }
    }
    allSanityProduct(filter: { id: { eq: $id } }) {
      nodes {
        ...Product
        seo {
          ...SanitySeo
        }
      }
    }
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
  }
`
