import styled from "styled-components"
import { mq, zIndexes, typography } from "styles"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { siteConstants } from "constants/site"
import { $Selected } from "typings/modules"

export const CustomizeTrigger = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 8px;
  ${typography.bodySmall};
  color: ${({ theme }) => theme.global?.dropdown?.primaryText?.opacityHex};
`

export const Drawer = styled.div`
  position: fixed;
  bottom: ${siteConstants.mobileFixedFooterHeight}px;
  left: 0;
  right: 0;
  z-index: ${zIndexes.zIndex8};
  background: ${({ theme }) => theme.global?.dropdown?.fill?.opacityHex};
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider.opacityHex};
  ${mq.minWidth("md")} {
    bottom: ${siteConstants.desktopFixedFooterHeight}px;
  }
`

export const MultiVariantContainer = styled.div`
  padding: 16px;
  ${mq.minWidth("sm")} {
    padding: 24px 48px;
  }
`

export const Veil = styled(NoStyleButton)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: ${siteConstants.mobileFixedFooterHeight}px;
  z-index: ${zIndexes.zIndex7};
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.16);
  ${mq.minWidth("md")} {
    bottom: ${siteConstants.desktopFixedFooterHeight}px;
  }
`

export const ListButton = styled(NoStyleButton)<$Selected>`
  color: ${({ theme }) => theme.global?.dropdown?.primaryText?.opacityHex};
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider.opacityHex};
  ${typography.bodySmall};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  background: ${({ $selected, theme }) =>
    $selected
      ? theme.global?.dropdown?.hoverFill?.opacityHex
      : theme.global?.dropdown?.fill?.opacityHex};
  padding: 21px 16px;
  ${mq.maxWidth("md")} {
    padding: 16px;
  }
`

export const ListText = styled.span`
  margin-left: 8px;
  ${typography.bodySmall}
`
export const CalloutsContainer = styled.div`
  margin: 10px 0;
`

export const VariantContainer = styled.div``
