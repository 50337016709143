import React, { useCallback, useContext } from "react"
import { ProductContext } from "contexts/ProductContext"
import { Button } from "components/UI/Button/Button"
import { StoreContext } from "contexts/StoreContext"
import { graphql, useStaticQuery } from "gatsby"
import { Query } from "typings/graphql"
import { useFlags } from "launchdarkly-react-client-sdk"

type Props = {
  loading: boolean
  actionRequired?: boolean
  text?: string
  fullWidth?: boolean
}

const staticAddToCartQuery = graphql`
  query {
    sanityGlobalSettings {
      waitlistSignupText
    }
  }
`

export const AddToCartButton = ({
  loading,
  actionRequired,
  text,
  fullWidth,
}: Props) => {
  const {
    selectedVariant,
    setWaitlistPopupVisible,
    quantity,
    addedProductVariantQuantities,
  } = useContext(ProductContext)
  const flags = useFlags()
  const { addLineItems, setCartOpen } = useContext(StoreContext)

  const addToCartClicked = useCallback(() => {
    if (selectedVariant?.availableForSale) {
      const productVariants = Object.entries(
        addedProductVariantQuantities
      ).map(([variantId, quantity]) => ({ variantId, quantity }))

      productVariants.push({
        variantId: selectedVariant?.shopifyVariant?.variantId,
        quantity,
      })

      addLineItems(productVariants)
      setCartOpen(true)
    }
  }, [
    addLineItems,
    quantity,
    setCartOpen,
    selectedVariant,
    addedProductVariantQuantities,
  ])

  const available =
    !loading && !actionRequired && selectedVariant?.availableForSale

  // Feature Flag - Waitlist
  const waitlist =
    (flags.waitlistEnabled
      ? !selectedVariant?.disableWaitlist
      : selectedVariant?.variantMetadata?.key === "waitlistCallout") &&
    !available

  const hasAddOns = Object.values(addedProductVariantQuantities).some(
    quantity => quantity > 0
  )
  const { sanityGlobalSettings } = useStaticQuery<Query>(staticAddToCartQuery)
  const waitlistSignupText =
    selectedVariant?.waitlistSignupText ||
    sanityGlobalSettings?.waitlistSignupText ||
    "Waitlist"

  const buttonText = actionRequired
    ? text
    : available
    ? hasAddOns
      ? "Add All to Cart"
      : "Add to Cart"
    : "Sold Out"

  return waitlist ? (
    <Button
      type="button"
      fullWidth={fullWidth}
      onClick={() => setWaitlistPopupVisible(true)}
      variant="primary-dark"
      loading={loading}
    >
      {waitlistSignupText}
    </Button>
  ) : (
    <Button
      onClick={addToCartClicked}
      fullWidth={fullWidth}
      data-test="product-page-submit"
      aria-label={buttonText}
      variant={available ? "primary-dark" : "disabled"}
      loading={loading}
    >
      {buttonText}
    </Button>
  )
}
