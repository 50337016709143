import React, { useContext } from "react"
import { ProductContext } from "contexts/ProductContext"
import styled from "styled-components"
import { typography } from "styles"

const Callout = styled.span`
  color: ${({ theme }) => theme.global?.notification?.errorFill?.opacityHex};
  ${typography.bodySmall};
  display: grid;
  align-items: center;
  justify-content: center;
`

export const Callouts = () => {
  const { selectedVariant } = useContext(ProductContext)
  const { key, value } = selectedVariant?.variantMetadata || {}

  const waitlistedItemAvailable =
    key === "waitlistCallout" && selectedVariant.availableForSale

  if (key && !waitlistedItemAvailable) {
    return <Callout>{value}</Callout>
  }
  return null
}
