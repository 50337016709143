import React, { useState } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { Config } from "config/brands/index"
import { tracking } from "utils/tracking"
import { Button } from "components/UI/Button/Button"
import {
  Form,
  Label,
  Fields,
  Input,
  Notice,
  Error as ErrorMessage,
  Success,
} from "./WaitlistSignup.styles"
import { useFlags } from "launchdarkly-react-client-sdk"

type Props = {
  sku: string
  variantId: string
}

const SignupSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
})

export const WaitlistSignup = ({ sku, variantId }: Props) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")
  const flags = useFlags()

  const subscribe = async ({
    email,
    variantId,
  }: {
    email: string
    variantId: string
  }) => {
    try {
      setError("")
      const response = await fetch("/api/waitlist/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          variantId,
        }),
      })

      const data = await response.json()

      if (data.success) {
        setSuccess(true)
      } else {
        throw new Error(`We're sorry, Something went wrong!`)
      }
    } catch (error) {
      console.error(error)
      setError(`We're sorry, Something went wrong!`)
    }
  }

  return (
    <Formik
      initialValues={{ email: "" }}
      initialStatus={{ sent: false, focused: false }}
      validationSchema={SignupSchema}
      onSubmit={({ email }) => {
        const identityAffiliation = Config.shopify?.id
        const hashedEmail = window.btoa(email)
        window.analytics?.identify(hashedEmail, {
          identityAffiliation,
          email,
        })
        tracking.emailRegistered(
          `${identityAffiliation} Waitlist - ${sku}`,
          email
        )
        // Feature Flag - Waitlist
        if (flags.waitlistEnabled) {
          subscribe({ email, variantId })
        }
      }}
    >
      {({ handleBlur, touched, errors, status, setStatus }) => (
        <Form>
          <Label htmlFor={`waitlist-email-${sku}`}>Email address</Label>
          <Fields>
            <Input
              id={`waitlist-email-${sku}`}
              name="email"
              autoComplete="off"
              placeholder="Email Address"
              onFocus={() => {
                setStatus({ ...status, focused: true })
              }}
              onBlur={(event: FocusEvent) => {
                setStatus({ ...status, focused: false })
                handleBlur(event)
              }}
            />
            <Button variant="primary-dark" type="submit">
              Sign Up
            </Button>
          </Fields>
          <Notice>
            {touched.email && errors.email && (
              <ErrorMessage>{errors.email}</ErrorMessage>
            )}
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {success && !errors.email && (
              <Success>Thanks for signing up!</Success>
            )}
          </Notice>
        </Form>
      )}
    </Formik>
  )
}
