import React, {
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react"
import {
  AvailableOptionPairs,
  AvailableOptions,
  MergedVariant,
  SelectedOptions,
  SelectedOptionsKey,
  UpdateSelectedVariant,
} from "typings/modules"
import {
  Maybe,
  SanityImage,
  SanityImageOrVariantVideoAsset,
  SanityProduct,
  ShopifyProduct,
  YotpoProduct,
} from "typings/graphql"
import { getVariantFromOptions } from "utils/productUtils"

export type ProductContextProps = {
  item: Maybe<SanityProduct>
  setItem: Dispatch<SetStateAction<Maybe<SanityProduct>>>
  product: Maybe<ShopifyProduct>
  setProduct: Dispatch<SetStateAction<Maybe<ShopifyProduct>>>
  variants: MergedVariant[]
  setVariants: Dispatch<SetStateAction<MergedVariant[]>>
  addOnProductVariants: MergedVariant[]
  setAddOnProductVariants: Dispatch<SetStateAction<MergedVariant[]>>
  quantity: number
  setQuantity: Dispatch<SetStateAction<number>>
  addedProductVariantQuantities: Record<string, number>
  updateAddOnProductVariantQuantity: (
    variantId: string,
    quantity: number
  ) => void
  addedProductVariantsPrice: number
  setAddedProductVariantsPrice: Dispatch<SetStateAction<number>>
  waitlistPopupVisible: boolean
  setWaitlistPopupVisible: Dispatch<SetStateAction<boolean>>
  selectedVariant: MergedVariant | undefined
  setSelectedVariant: Dispatch<SetStateAction<MergedVariant | undefined>>
  selectedAssets: Maybe<Array<Maybe<SanityImage>>>
  setSelectedAssets: Dispatch<
    SetStateAction<Maybe<Array<Maybe<SanityImageOrVariantVideoAsset>>>>
  >
  variantTypes: SelectedOptionsKey[]
  setVariantTypes: Dispatch<SetStateAction<SelectedOptionsKey[]>>
  availableOptions: AvailableOptions
  setAvailableOptions: Dispatch<SetStateAction<AvailableOptions>>
  selectableOptions: AvailableOptionPairs
  setSelectableOptions: Dispatch<SetStateAction<AvailableOptionPairs>>
  updateSelectedVariant: UpdateSelectedVariant
  review: Maybe<YotpoProduct>
  dataPopulationComplete: boolean
  setDataPopulationComplete: Dispatch<SetStateAction<boolean>>
  actionRequired: boolean
  actionRequiredText: string
  resetProduct: () => void
  viewDimensionsClicked: () => void
  setViewDimensionsCallback: (callback: () => void) => void
}
export const ProductContext = createContext<ProductContextProps>(
  {} as ProductContextProps
)

type ProductProviderProps = {
  children: ReactNode
  review: Maybe<YotpoProduct>
}

export const ProductProvider = ({ review, children }: ProductProviderProps) => {
  const [quantity, setQuantity] = useState(1)
  const [actionRequired, setActionRequired] = useState(false)
  const [actionRequiredText, setActionRequiredText] = useState("")
  const [
    addedProductVariantQuantities,
    setAddedProductVariantQuantities,
  ] = useState<Record<string, number>>({})
  const [
    addedProductVariantsPrice,
    setAddedProductVariantsPrice,
  ] = useState<number>(0)
  const [waitlistPopupVisible, setWaitlistPopupVisible] = useState(false)
  const [item, setItem] = useState<Maybe<SanityProduct>>(null)
  const [viewDimensionsCallbackFn, setViewDimensionsCallbackFn] = useState(null)
  const [product, setProduct] = useState<Maybe<ShopifyProduct>>(null)
  const [variants, setVariants] = useState<MergedVariant[]>([])
  const [addOnProductVariants, setAddOnProductVariants] = useState<
    MergedVariant[]
  >([])
  const [availableOptions, setAvailableOptions] = useState<AvailableOptions>(
    null
  )
  const [
    selectableOptions,
    setSelectableOptions,
  ] = useState<AvailableOptionPairs>(null)
  const [variantTypes, setVariantTypes] = useState<SelectedOptionsKey[]>([])
  const [selectedVariant, setSelectedVariant] = useState<
    MergedVariant | undefined
  >()
  const [selectedAssets, setSelectedAssets] = useState<
    Maybe<Array<Maybe<SanityImage>>>
  >([])
  const [dataPopulationComplete, setDataPopulationComplete] = useState(false)
  const resetProduct = () => {
    setVariants([])
    setSelectedVariant(undefined)
    setSelectedAssets([])
    setDataPopulationComplete(false)
  }
  const updateSelectedVariant = (
    previousVariant: MergedVariant,
    changedOption: SelectedOptions
  ) => {
    const newVariant = getVariantFromOptions(
      variants,
      previousVariant,
      changedOption
    )
    setSelectedVariant(newVariant)
  }

  const updateAddOnProductVariantQuantity = (
    variantId: string,
    newQuantity: number
  ) => {
    if (newQuantity > 0) {
      setAddedProductVariantQuantities({
        ...addedProductVariantQuantities,
        [variantId]: newQuantity,
      })
    } else {
      delete addedProductVariantQuantities[variantId]
      setAddedProductVariantQuantities({ ...addedProductVariantQuantities })
    }
  }

  useEffect(() => {
    const price = selectedVariant?.price ? parseFloat(selectedVariant.price) : 0
    const required = price === 0 && addedProductVariantsPrice === 0
    setActionRequiredText(item?.addOnProductsTitleText)
    setActionRequired(required)
  }, [
    selectedVariant?.price,
    addedProductVariantsPrice,
    setActionRequired,
    item,
  ])

  return (
    <ProductContext.Provider
      value={{
        item,
        setItem,
        product,
        setProduct,
        variants,
        setVariants,
        addOnProductVariants,
        setAddOnProductVariants,
        quantity,
        setQuantity,
        addedProductVariantQuantities,
        updateAddOnProductVariantQuantity,
        addedProductVariantsPrice,
        setAddedProductVariantsPrice,
        waitlistPopupVisible,
        setWaitlistPopupVisible,
        selectedVariant,
        setSelectedVariant,
        selectedAssets,
        setSelectedAssets,
        variantTypes,
        setVariantTypes,
        availableOptions,
        setAvailableOptions,
        selectableOptions,
        setSelectableOptions,
        updateSelectedVariant,
        review,
        dataPopulationComplete,
        setDataPopulationComplete,
        actionRequired,
        actionRequiredText,
        resetProduct,
        setViewDimensionsCallback: fn => setViewDimensionsCallbackFn(() => fn),
        viewDimensionsClicked: () =>
          viewDimensionsCallbackFn && viewDimensionsCallbackFn(),
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export const ProductConsumer = ProductContext.Consumer
