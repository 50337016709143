import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import styled from "styled-components"
import { mq, typography } from "styles"

export const Container = styled(NoStyleButton)`
  background: ${({ theme }) => theme.dark.canvas?.secondary?.opacityHex};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  padding: 15px 16px 15px 47px;
  display: grid;
  margin-top: 24px;
  position: relative;
  width: 100%;
  text-align: left;
  svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    circle {
      fill: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
    }
    path {
      fill: ${({ theme }) => theme.dark.canvas?.secondary?.opacityHex};
    }
  }
  ${mq.minWidth("md")} {
    padding: 15px 25px 15px 47px;
    svg {
      left: 16px;
    }
  }
`

export const Content = styled.div`
  display: grid;
  justify-content: center;
`

export const Message = styled.p`
  display: inline;
  ${typography.bodySmall};
  img {
    display: inline;
  }
`
