import React from "react"
import { ShopifyProduct, YotpoProduct } from "typings/graphql"

type ProductSchemaType = {
  product: ShopifyProduct
  productPathPrefix?: string
  yotpoProduct: YotpoProduct
}

const makeProductSchema = (
  product: ShopifyProduct,
  productPathPrefix: string,
  review: YotpoProduct
) => {
  const variant = product?.variants?.[0]
  const bottomline = review?.bottomline
  const reviews = review?.reviews

  const reviewsObj = reviews?.map(review => ({
    "@type": "Review",
    author: review?.user?.displayName,
    datePublished: review?.createdAt,
    description: review?.content,
    reviewRating: {
      "@type": "Rating",
      bestRating: 5,
      ratingValue: review?.score,
      worstRating: 1,
    },
  }))

  const productSchema: any = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: product?.title,
    brand: "Pattern Brands",
    sku: variant?.sku,
    url: `${productPathPrefix}${product?.handle}/`,
    image: product?.images?.[0]?.originalSrc,
    description: product?.description,
    offers: {
      "@type": "Offer",
      availability: "http://schema.org/InStock",
      priceCurrency: "USD",
      price: variant?.price,
    },
    review: reviewsObj,
  }

  if (bottomline) {
    productSchema.aggregateRating = {
      "@type": "AggregateRating",
      reviewCount: bottomline.totalReview,
      ratingValue: bottomline.averageScore,
    }
  }

  return productSchema
}

export const ProductSchema = ({
  product,
  productPathPrefix,
  yotpoProduct,
}: ProductSchemaType) => {
  return (
    <script
      key={`productJSON-${product?.id}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          makeProductSchema(product, productPathPrefix, yotpoProduct)
        ),
      }}
    />
  )
}
