import React from "react"
import { Grid } from "components/UI/Grid/Grid"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import {
  Container,
  BoxContainer,
  Box,
  Header,
  Subheader,
  CloseIcon,
} from "./Waitlist.styles"
import { graphql, useStaticQuery } from "gatsby"
import { Query } from "typings/graphql"

type Props = {
  onClose: () => void
}

const staticWaitlistQuery = graphql`
  query {
    sanityGlobalSettings {
      waitlistModalTitle
      waitlistModalText
    }
  }
`

export const Waitlist: React.FC<Props> = ({ onClose, children }) => {
  const { sanityGlobalSettings } = useStaticQuery<Query>(staticWaitlistQuery)
  const waitlistModalTitle = sanityGlobalSettings?.waitlistModalTitle
  const waitlistModalText = sanityGlobalSettings?.waitlistModalText

  return (
    <Container>
      <BoxContainer base="[12]" md="1 [14] 1" lg="6 [12] 6">
        <Box>
          <Grid base="1 [10] 1" md="1 [14] 1" lg="1 [10] 1">
            <Header>{waitlistModalTitle}</Header>
            <Subheader>{waitlistModalText}</Subheader>
            <Grid base="[10]" md="1 [12] 1" lg="1 [8] 1">
              {children}
            </Grid>
          </Grid>
          <NoStyleButton aria-label="Close Waitlist Modal" onClick={onClose}>
            <CloseIcon role="img" aria-label="Close Icon" />
          </NoStyleButton>
        </Box>
      </BoxContainer>
    </Container>
  )
}
