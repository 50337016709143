import React from "react"
import loadable from "@loadable/component"
import { ComponentBlock } from "components/Helpers/ComponentBlock"
import { Query } from "typings/graphql"
const SanityTileMatDesignToolBlock = loadable(
  () => import("components/TileMat/SanityTileMatDesignTool")
)
const SanityLetterBoardDesignToolBlock = loadable(
  () => import("components/LetterBoard/SanityLetterBoardDesignTool")
)
const SanityProductListBlock = loadable(
  () => import("components/ProductList/SanityProductList")
)
const SanityProductCollectionBlock = loadable(
  () => import("components/ProductCollection/SanityProductCollection")
)
const SanityHeroListBlock = loadable(
  () => import("components/Hero/SanityHeroList")
)
const SanityFiftyFiftyListBlock = loadable(
  () => import("components/FiftyFiftyList/SanityFiftyFiftyList")
)
const SanityFBTBlock = loadable(() => import("components/FBT/SanityFBT"))
const SanityValuePropListBlock = loadable(
  () => import("components/ValuePropList/SanityValuePropList")
)
const SanityFlexibleGridListBlock = loadable(
  () => import("components/FlexibleGridList/SanityFlexibleGridList")
)
const SanityMarkdownBlock = loadable(
  () => import("components/Markdown/SanityMarkdown")
)
const SanityAccordionListBlock = loadable(
  () => import("components/AccordionList/SanityAccordionList")
)
const SanityUgcListBlock = loadable(
  () => import("components/UGCList/SanityUGCList")
)
const SanityYotpoReviewListBlock = loadable(
  () => import("components/YotpoReviewList/SanityYotpoReviewList")
)
const SanityReviewListBlock = loadable(
  () => import("components/ReviewList/SanityReviewList")
)
const SanityBlogCategoryListBlock = loadable(
  () => import("components/BlogCategoryList/SanityBlogCategoryList")
)

// The below craziness is so we don't have to manually build up a type as we add
// more fragments to the query. graphql-codegen does generate a unique type for this
// but the type name is hundreds of characters long and hideous.
type Unpacked<T> = T extends (infer U)[] ? U : T
type BlocksArrayType = NonNullable<Query["sanityPage"]>["blocks"]
type BlockType = Unpacked<BlocksArrayType> & {
  _key?: string
  __typename?: string
}

const componentMap = {
  SanityTileMatDesignTool: SanityTileMatDesignToolBlock,
  SanityLetterBoardDesignTool: SanityLetterBoardDesignToolBlock,
  SanityProductList: SanityProductListBlock,
  SanityProductCollection: SanityProductCollectionBlock,
  SanityHeroList: SanityHeroListBlock,
  SanityFiftyFiftyList: SanityFiftyFiftyListBlock,
  SanityFBT: SanityFBTBlock,
  SanityValuePropList: SanityValuePropListBlock,
  SanityMarkdown: SanityMarkdownBlock,
  SanityUgcList: SanityUgcListBlock,
  SanityAccordionList: SanityAccordionListBlock,
  SanityFlexibleGridList: SanityFlexibleGridListBlock,
  SanityYotpoReviewList: SanityYotpoReviewListBlock,
  SanityReviewList: SanityReviewListBlock,
  SanityBlogCategoryList: SanityBlogCategoryListBlock,
}

export const renderBlock = (block: BlockType, index: number) => {
  const type = block.__typename
  const SanityComponent = componentMap[type as keyof typeof componentMap]
  return SanityComponent ? (
    <ComponentBlock key={index} item={block as any}>
      <SanityComponent index={index} fields={block as any} />
    </ComponentBlock>
  ) : null
}
