import React from "react"
import { Grid } from "components/UI/Grid/Grid"
import { ProductGallery } from "./ProductGallery"
import { ProductInfo } from "./ProductInfo"
import { Mode } from "typings/modules"
import { Query } from "typings/graphql"
import { getProductDataToPreRender } from "utils/productUtils"

type Props = Mode & {
  hasAReviewComponent: boolean
  staticData: Query
}

export const PDPAboveTheFold = ({
  mode,
  hasAReviewComponent,
  staticData,
}: Props) => {
  const { item, variant } = getProductDataToPreRender(staticData)
  return (
    <Grid base="[12]" md="[16]" lg="[14] [10]">
      <ProductGallery
        itemTitleForAltText={item?.title}
        mode={mode}
        preRenderVariant={variant}
      />
      <ProductInfo
        preRenderItem={item}
        mode={mode}
        hasAReviewComponent={hasAReviewComponent}
      />
    </Grid>
  )
}
