import React, { ReactElement } from "react"
import { Mode } from "typings/modules"
import { Maybe, SanityImage } from "typings/graphql"
import { getImage } from "utils/imageUtils"
import {
  SlideshowContainer,
  ImageContainer,
  ImageThumb,
  VideoThumb,
  VideoIcon,
} from "./ProductGalleryDesktopDots.styles"

type ProductDotsProps = Mode & {
  dots: ReactElement[]
  items?: Maybe<SanityImage>[] | null
  itemTitleForAltText?: string
}

export const ProductGalleryDesktopDots = ({
  dots,
  items,
  mode,
  itemTitleForAltText,
}: ProductDotsProps) => {
  return (
    <SlideshowContainer>
      {items?.map((slide, index) => {
        const matchingDot = dots[index]
        const clickHandler = matchingDot?.props?.children?.props?.onClick
        const isActive = matchingDot?.props?.className === "slick-active"
        const isImage = slide.__typename === "SanityImage"
        return (
          <ImageContainer
            aria-label={`Go to Image #${index}`}
            key={index}
            onClick={clickHandler}
            $active={isActive}
            $mode={mode}
          >
            {isImage ? (
              <ImageThumb
                image={getImage(slide)?.gatsbyImage}
                alt={`Thumbnail #${index} for item ${itemTitleForAltText}`}
              />
            ) : (
              <VideoThumb $mode={mode}>
                <VideoIcon $mode={mode} />
              </VideoThumb>
            )}
          </ImageContainer>
        )
      })}
    </SlideshowContainer>
  )
}
