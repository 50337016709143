import React, { ReactElement } from "react"
import styled from "styled-components"
import { zIndexes } from "styles"
import { $Mode, Active, Mode } from "typings/modules"
import { Maybe, SanityImage } from "typings/graphql"

type ProductDotsProps = Mode & {
  dots: ReactElement[]
  items?: Maybe<SanityImage>[] | null
}

const SlideshowContainer = styled.div`
  bottom: 48px;
  width: 100%;
  position: absolute;
  z-index: ${zIndexes.zIndex1TextAboveImage};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Dot = styled.div<Active & $Mode>`
  position: relative;
  cursor: pointer;
  height: 8px;
  width: 8px;
  background: ${({ theme, $mode }) => theme[$mode]?.ui?.icon?.opacityHex};
  border-radius: 50%;
  opacity: 1;
  margin-right: 20px;
  ${({ active, theme, $mode }) =>
    active &&
    `
    background-color: transparent;
    box-shadow: 0 0 0 3px ${theme[$mode]?.ui?.icon?.opacityHex};
  `}
`

export const ProductGalleryMobileDots = ({
  dots,
  items,
  mode,
}: ProductDotsProps) => {
  return (
    <SlideshowContainer>
      {items?.map((image, index) => {
        const matchingDot = dots[index]
        const clickHandler = matchingDot?.props?.children?.props?.onClick
        const isActive = matchingDot?.props?.className === "slick-active"
        return (
          <Dot
            key={index}
            $mode={mode}
            onClick={clickHandler}
            active={isActive}
          />
        )
      })}
    </SlideshowContainer>
  )
}
