import React, { useContext, useEffect, useState, useCallback } from "react"
import { AddToCart } from "components/Products/AddToCart"
import { FixedProductBar } from "components/Products/FixedProductBar"
import { ProductDetails } from "components/Products/ProductDetails"
import { LinkedProducts } from "components/Products/LinkedProducts"
import { templateReplace } from "utils/stringUtils"
import { usdFmt } from "utils/priceUtils"
import { Mode } from "typings/modules"
import { ProductContext } from "contexts/ProductContext"
import { ReviewStarsWithNumber } from "components/YotpoReviewList/ReviewStarsWithNumber"
import { Tag } from "components/UI/Tag/Tag"
import {
  Container,
  StyledGrid,
  InnerContainer,
  Title,
  Prices,
  Price,
  CompareAtPrice,
  CostPerItem,
  Description,
  PriceReviewContainer,
  ReviewContainer,
  TagContainer,
  ProductAccordionList,
} from "./ProductInfo.styles"
import { SanityProduct } from "typings/graphql"
import { AccordionItem } from "components/AccordionItem/AccordionItem"
import { DetailsAccordionProduct } from "./DetailsAccordionProduct"
import { useMinReviewsNumber } from "hooks/useMinReviewsNumber"
import { isSanityAccordionItemBlogLink } from "utils/productUtils"
import { AccordionItemBlogLink } from "./AccordionItemBlogLink"

type Props = Mode & {
  hasAReviewComponent: boolean
  preRenderItem: SanityProduct
}

export const ProductInfo = ({
  mode,
  hasAReviewComponent,
  preRenderItem,
}: Props) => {
  const [formattedPrice, setFormattedPrice] = useState(null)
  const [pricePerItem, setPricePerItem] = useState("")
  const [compareAtPrice, setCompareAtPrice] = useState("")
  const [addToCartVisible, setAddToCartVisible] = useState(false) //used to track button visibility on the screen
  const {
    item,
    selectedVariant,
    dataPopulationComplete,
    review,
    addedProductVariantsPrice,
  } = useContext(ProductContext)
  const renderedItem = item || preRenderItem
  const title = renderedItem?.title || ""
  const description = renderedItem?.description || ""
  const detailsTitle = renderedItem?.detailsTitle || ""
  const detailsItems =
    selectedVariant?._rawDetails || renderedItem?._rawDetails || []
  const numberOfItemsInSet = renderedItem?.numberOfItemsInSet || 0
  const pricePerItemText = item?.pricePerItemText || ""
  const starCount = review?.bottomline?.averageScore || 0
  const reviewCount = review?.bottomline?.totalReview || 0
  const minReviewsNumber = useMinReviewsNumber()
  const showReviews = reviewCount >= minReviewsNumber
  const calc = useCallback(() => {
    if (selectedVariant) {
      const newPrice = selectedVariant.price
        ? parseFloat(selectedVariant.price)
        : 0

      let formatted = usdFmt(newPrice)
      if (addedProductVariantsPrice) {
        formatted += `+${usdFmt(addedProductVariantsPrice)}`
      }
      setFormattedPrice(formatted)

      setCompareAtPrice(selectedVariant.compareAtPrice ?? "")
      const calcItemPrice = numberOfItemsInSet
        ? newPrice / numberOfItemsInSet
        : newPrice
      setPricePerItem(usdFmt(calcItemPrice))
    }
  }, [numberOfItemsInSet, selectedVariant, addedProductVariantsPrice])

  useEffect(calc, [calc])

  return (
    <Container aria-label={`${title} Product Info`} role="region">
      <StyledGrid base="1 [10] 1" md="2 [12] 2" lg="1 [8] 1">
        <InnerContainer>
          {item?.pdpTag && (
            <TagContainer>
              <Tag tag={item?.pdpTag} />
            </TagContainer>
          )}
          <Title role="heading">{title}</Title>
          <PriceReviewContainer>
            {showReviews && (
              <ReviewContainer>
                <ReviewStarsWithNumber
                  reviewCount={reviewCount}
                  starCount={starCount}
                  mode={mode}
                  clickable={hasAReviewComponent && reviewCount > 0}
                />
              </ReviewContainer>
            )}
            <Prices aria-label="Price">
              {compareAtPrice && (
                <CompareAtPrice>
                  <del>{usdFmt(compareAtPrice)}</del>
                </CompareAtPrice>
              )}
              {!!formattedPrice && <Price>{formattedPrice}</Price>}
              {pricePerItemText && (
                <CostPerItem>
                  {templateReplace(pricePerItemText, pricePerItem)}
                </CostPerItem>
              )}
            </Prices>
          </PriceReviewContainer>
          <Description role="article">{description}</Description>
          {!!item?.dropdown && <LinkedProducts />}
          <AddToCart
            dimensionsButtonEnabled={renderedItem?.seeDimensionsButtonEnabled}
            loading={!dataPopulationComplete}
            onAddToCartVisibilityChange={visible => {
              setAddToCartVisible(visible)
            }}
          />
          <ProductAccordionList styleType={"secondary"}>
            <AccordionItem
              styleType="secondary"
              title={detailsTitle}
              openByDefault={renderedItem.detailsExpanded}
              eventTrackingId={`${renderedItem?.internalName}-details`}
            >
              <ProductDetails items={detailsItems} />
            </AccordionItem>
            {renderedItem?.accordionItems.map(accordionItem => (
              <AccordionItem
                key={accordionItem.id}
                styleType="secondary"
                title={accordionItem.title}
                openByDefault={accordionItem.expanded}
                eventTrackingId={`${renderedItem?.internalName}-${accordionItem.title}`}
              >
                {isSanityAccordionItemBlogLink(accordionItem) ? (
                  <AccordionItemBlogLink
                    {...accordionItem}
                    eventTrackingId={`${renderedItem?.internalName}-${accordionItem.title}`}
                  />
                ) : !!accordionItem.product ? (
                  <DetailsAccordionProduct
                    productItem={accordionItem.product}
                    mode={mode}
                  />
                ) : (
                  <ProductDetails items={accordionItem._rawContent} />
                )}
              </AccordionItem>
            ))}
          </ProductAccordionList>
        </InnerContainer>
      </StyledGrid>
      <FixedProductBar
        title={title}
        loading={!dataPopulationComplete}
        mode={mode}
        addToCartVisible={addToCartVisible}
      />
    </Container>
  )
}
