import styled from "styled-components"
import { zIndexes, animations } from "styles"
import { $Mode, $Active } from "typings/modules"
import { GatsbyImage } from "gatsby-plugin-image"
import VideoThumbSvg from "assets/svg/VideoThumb.svg"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

export const SlideshowContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: ${zIndexes.zIndex1TextAboveImage};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

export const ImageContainer = styled(NoStyleButton)<$Active & $Mode>`
  position: relative;
  cursor: pointer;
  height: 64px;
  width: 64px;
  margin-right: 16px;
  transition: border-color 400ms ${animations.defaultBezier};
  border: 1px solid
    ${({ $active, theme, $mode }) =>
      $active ? theme[$mode]?.ui?.icon?.opacityHex : "transparent"};
  will-change: border-color;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    border-color: ${({ theme, $mode }) => theme[$mode]?.ui?.icon?.opacityHex};
  }
`

export const ImageThumb = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

export const VideoThumb = styled.div<$Mode>`
  width: 100%;
  height: 100%;
  background: ${({ theme, $mode }) =>
    theme[$mode]?.canvas?.primary?.opacityHex};
`

export const VideoIcon = styled(VideoThumbSvg)<$Mode>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  color: ${({ theme, $mode }) => theme[$mode]?.ui?.icon?.opacityHex};
`
