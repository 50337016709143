import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AffirmTeaser } from "./AffirmTeaser"

type Props = {
  price: number
}

const query = graphql`
  query {
    allSanityGlobalSettings {
      nodes {
        disableAffirm
      }
    }
  }
`

export const AffirmTeaserSanity = ({ price }: Props) => {
  const data = useStaticQuery(query)
  const [node] = data?.allSanityGlobalSettings?.nodes
  const disableAffirm = node?.disableAffirm
  return disableAffirm ? null : <AffirmTeaser price={price} />
}
