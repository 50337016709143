import styled from "styled-components"
import { mq, zIndexes } from "styles"
import CloseSvg from "assets/svg/close.svg"
import { Grid } from "components/UI/Grid/Grid"

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.zIndex8Flyout};
  background-color: ${({ theme }) => theme.dark?.ui?.veil?.opacityHex};
`

export const BoxContainer = styled(Grid)`
  height: 100%;
  align-items: center;
`

export const Box = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme?.dark?.canvas?.primary?.opacityHex};
  text-align: center;
  padding: 80px 0;
  ${mq.minWidth("lg")} {
    padding: 90px 0;
  }
`

export const Header = styled.h3`
  color: ${({ theme }) => theme?.dark?.text?.primary?.opacityHex};
`

export const Subheader = styled.p`
  color: ${({ theme }) => theme?.dark?.text?.primary?.opacityHex};
  margin: 16px 0 40px;
`

export const CloseIcon = styled(CloseSvg)`
  position: absolute;
  display: block;
  top: 26px;
  right: 26px;
  width: 14px;
  height: 14px;
  path {
    fill: ${({ theme }) => theme?.dark?.text?.primary?.opacityHex};
  }
  cursor: pointer;
`
