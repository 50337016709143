import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { usdFmt } from "utils/priceUtils"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { GatsbyImage } from "gatsby-plugin-image"
import { Maybe, SanityDropdownItem } from "typings/graphql"
import { tracking } from "utils/tracking"
import { getImage } from "utils/imageUtils"
import { DropdownItem } from "components/UI/Dropdown/Dropdown.styles"

type ProductLinkProps = {
  link: Maybe<
    SanityDropdownItem & {
      price: number
      sku: string
      variantId: string
    }
  >
}

const LinkedProductLeft = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled(GatsbyImage)`
  width: 24px;
  margin-right: 12px;
`

export const ProductLink = ({ link }: ProductLinkProps) => {
  const to = link?.link
  const linkIcon = getImage(link?.image)
  const linkPrice = usdFmt(link?.price || 0)
  const sku = link.sku
  const handle = link?.shopifyProduct?.handle || ""
  const price = link?.price
  const variantId = link?.variantId
  return (
    <DropdownItem
      aria-label={`Go to ${link?.title}`}
      onClick={() => {
        tracking.productDropdownSelected(sku, handle, to, price, variantId)
        navigate(to)
      }}
    >
      <LinkedProductLeft>
        {linkIcon && (
          <Icon image={linkIcon?.gatsbyImage} alt={`Icon for ${link?.title}`} />
        )}
        <span>{link?.title}</span>
      </LinkedProductLeft>
      {price && <span>{linkPrice}</span>}
    </DropdownItem>
  )
}
