import React, {
  useEffect,
  useCallback,
  useContext,
  useState,
  ReactElement,
} from "react"
import loadable from "@loadable/component"
import { mq } from "styles"
import { ProductContext } from "contexts/ProductContext"
import { ProductGalleryDesktopDots } from "components/Products/ProductGalleryDesktopDots"
import { ProductGalleryMobileDots } from "./ProductGalleryMobileDots"
import { MergedVariant, Mode } from "typings/modules"
import {
  StyledSlider,
  FullImage,
  VideoContainer,
} from "./ProductGallery.styles"
import { resolveGatsbyImageData } from "utils/imageUtils"
import {
  SanityImageOrVariantVideoAsset,
  SanityVariantOption,
} from "typings/graphql"
import Slider from "react-slick"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { useHeaderHeight } from "hooks/useHeaderHeight"
const Video = loadable(() => import("components/UI/Video/Video"))

type ProductGalleryProps = Mode & {
  preRenderVariant: SanityVariantOption
  itemTitleForAltText: string
}

type Slideables = (ReactElement | null)[] | undefined

const generateSlides = (
  assets: SanityImageOrVariantVideoAsset[],
  selectedVariant: MergedVariant
) =>
  assets?.map((slide, index) => {
    if (slide.__typename === "SanityImage") {
      const fallbackAlt = `Image #${index} for ${selectedVariant?.shopifyVariant?.title}`
      return (
        <FullImage
          key={slide?.asset?.assetId}
          image={resolveGatsbyImageData(slide, { quality: 85 })}
          alt={slide?.asset?.title || fallbackAlt}
          loading="eager"
        />
      )
    }
    const playbackId = slide.video.video?.asset?.playbackId
    return (
      <VideoContainer key={playbackId}>
        <Video playbackId={playbackId} autoplay />
      </VideoContainer>
    )
  })

export const ProductGallery = ({
  mode,
  preRenderVariant,
  itemTitleForAltText,
}: ProductGalleryProps) => {
  const {
    selectedVariant,
    selectedAssets,
    setViewDimensionsCallback: onViewDimensionsClicked,
  } = useContext(ProductContext)
  const headerHeight = useHeaderHeight()
  const [galleryItems, setGalleryItems] = useState<Slideables>(
    generateSlides(preRenderVariant?.assets, preRenderVariant as MergedVariant)
  )
  const { isMobile } = useCommonMediaQuery()
  const sliderRef = React.useRef<Slider>()

  const update = useCallback(() => {
    if (!selectedAssets?.length) return
    const newGalleryItems = generateSlides(selectedAssets, selectedVariant)
    setGalleryItems(newGalleryItems)
  }, [selectedAssets])
  useEffect(update, [update])

  useEffect(() => {
    onViewDimensionsClicked(() => {
      sliderRef?.current?.slickGoTo(galleryItems.length - 1)

      if (isMobile) {
        window.scrollTo({ behavior: "smooth", top: 0 })
      }
    })
  }, [sliderRef, galleryItems, isMobile])

  return galleryItems ? (
    <StyledSlider
      ref={sliderRef}
      key={`slider-${galleryItems
        .map(item => item?.key?.toString().slice(0, 6))
        .join("")}`}
      dots
      arrows
      infinite
      initialSlide={0}
      lazyLoad="ondemand"
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      responsive={[
        {
          breakpoint: mq.breakpoints.lg - 1,
          settings: {
            appendDots: dots => (
              <ProductGalleryMobileDots
                dots={dots as ReactElement[]}
                items={selectedAssets}
                mode={mode}
              />
            ),
            arrows: false,
          },
        },
      ]}
      appendDots={dots => (
        <ProductGalleryDesktopDots
          dots={dots as ReactElement[]}
          items={selectedAssets}
          mode={mode}
          itemTitleForAltText={itemTitleForAltText}
        />
      )}
      headerHeight={headerHeight}
    >
      {galleryItems}
    </StyledSlider>
  ) : (
    <div />
  )
}
