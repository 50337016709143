import React, { useContext } from "react"
import styled, { ThemeContext, ThemeProvider } from "styled-components"
import { typography } from "styles"
import { scrollToElement } from "utils/scrollUtils"
import { ReviewStars } from "./ReviewStars"
import { ModeType } from "typings/modules"

type $Clickable = { $clickable?: boolean }

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Rating = styled.span<$Clickable>`
  ${typography.bodySmall};
  white-space: nowrap;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
`

const ReviewCount = styled.span<$Clickable>`
  ${typography.bodySmall};
  white-space: nowrap;
  color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
`

const Description = styled.span`
  margin-left: 6px;
`

type ReviewStarsWithNumberProps = {
  reviewCount: number
  starCount: number
  mode: ModeType
  clickable: boolean
}

export const ReviewStarsWithNumber = ({
  reviewCount = 0,
  starCount,
  clickable,
  mode,
}: ReviewStarsWithNumberProps) => {
  const themeContext = useContext(ThemeContext)
  return (
    <ThemeProvider
      theme={{
        localTheme: themeContext[mode],
      }}
    >
      <Container>
        <ReviewStars
          starCount={starCount}
          reviewCount={reviewCount}
          renderSingle
        />
        <Description>
          {starCount > 0 ? (
            <>
              <Rating $clickable={clickable}>{starCount.toFixed(1)}</Rating>{" "}
            </>
          ) : null}
          <ReviewCount
            $clickable={clickable}
            onClick={() => clickable && scrollToElement("#yotpoReviews", 0)}
          >
            ({reviewCount})
          </ReviewCount>
        </Description>
      </Container>
    </ThemeProvider>
  )
}
