import styled from "styled-components"
import { typography, mq } from "styles"
import { Form as FormikForm, Field } from "formik"

export const Form = styled(FormikForm)`
  position: relative;
  display: grid;
  margin: 0;
`

export const Label = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 8px;
  ${mq.maxWidth("md")} {
    grid-template-columns: 1fr;
  }
`

export const Input = styled(Field)`
  ${typography.body};
  color: ${({ theme }) => theme.dark.text?.formActive?.opacityHex};
  background-color: ${({ theme }) => theme.dark?.ui?.formField?.opacityHex};
  padding: 0 20px;
  border: 0;
  outline: 0;
  appearance: none;
  ::placeholder {
    font: inherit;
    color: ${({ theme }) => theme.dark.text?.formInactive?.opacityHex};
    opacity: 1;
  }
  ${mq.maxWidth("md")} {
    height: 56px;
  }
`

export const Notice = styled.div`
  ${typography.bodySmall};
  padding-top: 8px;
  text-align: left;
`

export const Error = styled.div`
  color: ${({ theme }) => theme.dark.text?.formError?.opacityHex};
`

export const Success = styled.span`
  color: ${({ theme }) => theme.dark.text?.formSuccess?.opacityHex};
`
