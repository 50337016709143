import React from "react"
import styled from "styled-components"
import YotpoStar from "assets/svg/full-star.svg"
import HalfStar from "assets/svg/half-star.svg"

type ReviewStarsProps = {
  starCount: number
  reviewCount: number
  renderSingle?: boolean
}

type ActiveProps = {
  $active?: boolean
}

const StyledStar = styled(YotpoStar)<ActiveProps>`
  path {
    fill: ${({ $active }) =>
      $active
        ? `var(--reviews-star-fill);`
        : `var(--reviews-star-inactive-fill);`};
    stroke: ${({ $active }) =>
      $active
        ? `var(--reviews-star-stroke);`
        : `var(--reviews-star-inactive-stroke);`};
  }
  height: 16px;
  width: 16px;
  margin-right: 2px;
`

const StyledHalfStar = StyledStar.withComponent(HalfStar)

const StarContainer = styled.span`
  display: inline-flex;
  justify-content: center;
`

export const ReviewStars = ({
  starCount,
  reviewCount,
  renderSingle,
}: ReviewStarsProps) => {
  const doubleCount = Math.ceil(starCount * 2)
  const halfStarsNumber = doubleCount % 2
  const fullStarsNumber = (doubleCount - halfStarsNumber) / 2
  const inactiveStarsNumber = 5 - fullStarsNumber - halfStarsNumber
  const count =
    typeof starCount === "number" ? starCount : parseInt(starCount) ?? 0
  const fmtStarCount = parseInt(count.toFixed(1))
  const reviewCountText = `${reviewCount} ${
    reviewCount === 1 ? "Review" : "Reviews"
  }`
  const label =
    fmtStarCount !== 0
      ? `${reviewCountText} with an average score of ${fmtStarCount} stars out of 5`
      : `0 Reviews`
  return (
    <StarContainer role="img" aria-label={label}>
      {renderSingle ? (
        <StyledStar
          role="img"
          aria-label="Review Star"
          $active={Boolean(reviewCount)}
        />
      ) : (
        <>
          {Array.from(Array(fullStarsNumber).keys()).map(i => (
            <StyledStar
              role="img"
              aria-label="Review Star"
              key={`star${i}`}
              $active
            />
          ))}
          {halfStarsNumber ? <StyledHalfStar $active /> : null}
          {Array.from(Array(inactiveStarsNumber).keys()).map(i => (
            <StyledStar
              role="img"
              aria-label="Half a Review Star"
              key={`star${i}`}
            />
          ))}
        </>
      )}
    </StarContainer>
  )
}
