import React from "react"
import { SanityAccordionItemBlogLink } from "typings/graphql"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"
import { mq, typography } from "styles"
import { blogCatPath } from "utils/blogUtils"
import { rgba } from "polished"
import { tracking } from "utils/tracking"

const Container = styled.div`
  display: flex;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  ${typography.bodySmall};
`

const TextContainer = styled.div`
  margin-left: 9px;
  flex: 1;

  ${mq.minWidth("md")} {
    margin-left: 16px;
  }
`

const Title = styled.p`
  ${typography.body};
  font-weight: bold;
  margin-bottom: 6px;

  ${mq.minWidth("md")} {
    margin-bottom: 2px;
  }
`

const Excerpt = styled.p`
  ${typography.bodySmall};
  color: ${({ theme }) => rgba(theme.dark?.text?.primary?.opacityHex, 0.76)};
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${mq.minWidth("md")} {
    -webkit-line-clamp: 2;
  }
`

const ImgContainer = styled.div`
  width: 50%;
  position: relative;

  ${mq.minWidth("md")} {
    width: 180px;
    height: 180px;
  }
`

const Img = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const ReadMore = styled.a`
  ${typography.bodySmall};
  text-decoration: underline;
  margin-top: 16px;
  display: inline-block;
  color: ${({ theme }) => rgba(theme.dark?.text?.primary?.opacityHex, 0.76)};

  &:hover {
    text-decoration: none;
  }
`

type Props = SanityAccordionItemBlogLink & {
  eventTrackingId: string
}

export const AccordionItemBlogLink: React.FC<Props> = props => {
  const image = props.blogPost.image
  const altDescription =
    image?.asset?.description || `Image for ${props.blogPost.title}`

  return (
    <Container>
      <ImgContainer>
        {image && <Img {...image} alt={altDescription} />}
      </ImgContainer>
      <TextContainer>
        <Title>{props.blogPost.title}</Title>
        <Excerpt>{props.blogPost.blurb}</Excerpt>
        <ReadMore
          href={blogCatPath(props.blogPost)}
          target="_blank"
          onClick={() => {
            tracking.elementClicked(props.eventTrackingId)
          }}
        >
          Read More
        </ReadMore>
      </TextContainer>
    </Container>
  )
}
