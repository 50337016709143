import Slider from "react-slick"
import styled from "styled-components"
import { cursors, mq } from "styles"
import { GatsbyImage } from "gatsby-plugin-image"
import slickArrowNextWhite from "assets/images/slick-arrow-next-white.png"
import slickArrowPrevWhite from "assets/images/slick-arrow-prev-white.png"

export const StyledSlider = styled(Slider)<{ headerHeight: number }>`
  background-color: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
  padding-top: 100%;
  position: relative;
  ${mq.minWidth("lg")} {
    padding-top: 0;
    height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
    position: sticky !important;
    top: ${({ headerHeight }) => headerHeight}px;
  }
  .slick-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    .slick-track {
      height: 100%;
    }
    .slick-slide {
      height: 100%;
      position: relative;
      > div {
        height: 100%;
      }
    }
  }
  .slick-arrow {
    &:before {
      content: "";
    }
    padding: 0;
    transform: translate(0);
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
  }
  .slick-prev {
    ${mq.minWidth("lg")} {
      left: 0;
      &:hover {
        ${cursors.slickPrevWhiteCursor};
      }
      &:focus-visible {
        outline: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex}
          auto 1px;
      }
      &:focus-visible:not(:hover) {
        background-image: url(${slickArrowPrevWhite});
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  .slick-next {
    right: 0;
    ${mq.minWidth("lg")} {
      &:hover {
        ${cursors.slickNextWhiteCursor};
      }
      &:focus-visible {
        outline: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex}
          auto 1px;
      }
      &:focus-visible:not(:hover) {
        background-image: url(${slickArrowNextWhite});
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
`

export const FullImage = styled(GatsbyImage)`
  height: 100%;
`

export const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  color: red;
  > div > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
