import React, { useContext, useState, useCallback } from "react"
import usePortal from "react-useportal"
import { ProductContext } from "contexts/ProductContext"
import { DropdownButton } from "components/UI/Dropdown/DropdownButton"
import { ColorOptionUI } from "components/ColorOptions/ColorOptionUI"
import { Callouts } from "components/Products/Callouts"
import {
  CustomizeTrigger,
  Drawer,
  MultiVariantContainer,
  Veil,
  ListButton,
  ListText,
  VariantContainer,
  CalloutsContainer,
} from "./FixedCustomizeDrawer.styles"
import { getSelectedOptions } from "utils/productUtils"
import { VariantSettingsContext } from "contexts/VariantSettingsContext"
import { ProductPageVariantOptions } from "./VariantOptions"

export const FixedCustomizeDrawer = () => {
  const { Portal } = usePortal()
  const { shouldUseSwatches } = useContext(VariantSettingsContext)
  const [isOpen, setIsOpen] = useState(false)
  const {
    variantTypes,
    selectableOptions,
    selectedVariant,
    updateSelectedVariant,
  } = useContext(ProductContext)
  const isColorVarSetOnly =
    variantTypes.length === 1 && shouldUseSwatches(variantTypes[0])
  const colorOptions = selectableOptions?.Color
  const selectedOptions = getSelectedOptions(selectedVariant)
  const selectedOption = selectedOptions?.Color
  const buttonLabel = isColorVarSetOnly ? selectedOption?.value : "Customize"

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])
  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])
  const onClick = useCallback(
    option => () => {
      updateSelectedVariant(selectedVariant, {
        [variantTypes[0]]: option,
      })
    },
    [selectedOptions]
  )
  return (
    <>
      <DropdownButton
        onClick={handleToggle}
        isOpen={isOpen}
        button={
          <CustomizeTrigger>
            {isColorVarSetOnly && (
              <ColorOptionUI
                selected
                swatch={selectedVariant?.swatch}
                disabled={!selectedVariant?.availableForSale}
              />
            )}
            {buttonLabel}
          </CustomizeTrigger>
        }
      />
      {isOpen && (
        <Portal>
          <Drawer>
            <div>
              {isColorVarSetOnly ? (
                colorOptions?.map(({ option, optionVariant }) => {
                  return (
                    <ListButton
                      key={`Color-${option.value}`}
                      $selected={option === selectedOption}
                      onClick={onClick(option)}
                    >
                      <ColorOptionUI
                        selected={option.id === selectedOption.id}
                        swatch={optionVariant?.swatch}
                        disabled={!optionVariant?.availableForSale}
                      />
                      <ListText>{option.value}</ListText>
                    </ListButton>
                  )
                })
              ) : (
                <MultiVariantContainer>
                  <VariantContainer>
                    <ProductPageVariantOptions
                      selectableOptions={selectableOptions}
                      selectedVariant={selectedVariant}
                      updateSelectedVariant={updateSelectedVariant}
                    />
                  </VariantContainer>
                </MultiVariantContainer>
              )}
            </div>
            <CalloutsContainer>
              <Callouts />
            </CalloutsContainer>
          </Drawer>
          <Veil onClick={handleClose} />
        </Portal>
      )}
    </>
  )
}
