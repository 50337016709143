import { useStaticQuery, graphql } from "gatsby"
import { Query } from "typings/graphql"

const minReviewsNumberQuery = graphql`
  query {
    sanityGlobalSettings {
      minReviewsNumber
    }
  }
`

export const useMinReviewsNumber = () => {
  const { sanityGlobalSettings } = useStaticQuery<Query>(minReviewsNumberQuery)
  const minReviewsNumber = sanityGlobalSettings?.minReviewsNumber ?? 1
  return minReviewsNumber
}
