import React, { useContext, useEffect, useState, useMemo } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { ProductContext } from "contexts/ProductContext"
import { ProductLink } from "components/Products/ProductLink"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { tracking } from "utils/tracking"
import { Dropdown } from "components/UI/Dropdown/Dropdown"
import {
  Query,
  SanityProduct,
  ShopifyProductVariantEdge,
  ShopifyProductVariantGroupConnection,
} from "typings/graphql"
import { getImage } from "utils/imageUtils"
import { useStaticQuery } from "gatsby"
import { useProductList } from "hooks/useProductList"
import { encodeVariantId, decodeVariantId } from "utils/decodeId"

const sanityProductPathsQuery = graphql`
  query {
    allSanityProduct {
      nodes {
        _type
        internalName
        shopifyProduct {
          shopifyId
          handle
        }
        variants {
          disabled
          shopifyVariant {
            variantId
          }
        }
        path {
          current
        }
      }
    }
  }
`
const LinkedProductsContainer = styled.div`
  margin-bottom: 24px;
`

const Icon = styled(GatsbyImage)`
  width: 24px;
  margin-right: 12px;
`

export const LinkedProducts = () => {
  const [links, setLinks] = useState([])
  const { allSanityProduct } = useStaticQuery<Query>(sanityProductPathsQuery)
  const { item, product, selectedVariant } = useContext(ProductContext)
  const productPathPrefix = useProductPathPrefix()
  const sanityDropdownProducts = useMemo(() => {
    return (item.dropdown.items.map(i => ({
      _type: "product",
      shopifyProduct: i.shopifyProduct,
    })) as unknown) as SanityProduct[]
  }, [item.dropdown.items])
  const shopifyDropdownProducts = useProductList(sanityDropdownProducts)

  useEffect(() => {
    if (
      shopifyDropdownProducts.loading ||
      shopifyDropdownProducts.plItems.some(p => !p.product) ||
      !item?.dropdown?.items.length
    )
      return
    const linksWithPrices = item?.dropdown?.items.map(item => {
      const linkedProduct = shopifyDropdownProducts.plItems.find(
        p => p?.product?.handle === item.shopifyProduct.handle
      )
      if (!linkedProduct) {
        return null
      }
      const linkedProductVariants: ShopifyProductVariantEdge[] = ((linkedProduct
        ?.product
        ?.variants as unknown) as ShopifyProductVariantGroupConnection)?.edges?.filter(
        shopifyVariant =>
          allSanityProduct.nodes?.find(node =>
            node.variants.find(
              variant =>
                variant.shopifyVariant.variantId === shopifyVariant.node.id &&
                !variant.disabled
            )
          )
      )
      if (!linkedProductVariants.length) {
        return null
      }
      const variantIdFromLink = item.link?.match(/.*variant=(\d*)/)?.[1]
      const selectedVariant = variantIdFromLink
        ? linkedProductVariants.find(
            variant => variant.node.id === encodeVariantId(variantIdFromLink)
          )?.node
        : null
      const minPriceVariant = linkedProductVariants.sort(
        (variantA, variantB) => {
          return (
            parseFloat(variantA.node.price) - parseFloat(variantB.node.price)
          )
        }
      )[0]?.node
      const variant = selectedVariant || minPriceVariant
      const price = variant.price
      const sku = variant?.sku
      const variantId = variant?.id
      const sanityProduct = allSanityProduct.nodes?.find(
        node => node.shopifyProduct?.handle === item.shopifyProduct.handle
      )
      const link = `${productPathPrefix}${
        item.shopifyProduct.handle
      }?variant=${decodeVariantId(variant.id)}`
      return { ...item, price, sku, variantId, link, sanityProduct }
    })
    setLinks(linksWithPrices.filter(Boolean))
  }, [
    item?.dropdown,
    shopifyDropdownProducts.plItems,
    shopifyDropdownProducts.loading,
  ])
  const [firstLink, ...restLinks] = links
  return firstLink ? (
    <LinkedProductsContainer>
      <Dropdown
        position="bottom left"
        label={item.dropdown.label}
        renderButton={
          <>
            {firstLink.asset && (
              <Icon
                image={getImage(firstLink.asset)?.gatsbyImage}
                alt={`Icon for ${firstLink.title}`}
              />
            )}
            {firstLink.title}
          </>
        }
        renderList={
          <>
            {restLinks?.map((link, index) => (
              <ProductLink key={`link-${index}`} link={link} />
            ))}
          </>
        }
        onClick={(isOpen: boolean) => {
          if (!isOpen) {
            tracking.productDropdownClicked(
              selectedVariant?.shopifyVariant?.variantId ?? "",
              selectedVariant?.shopifyVariant?.sku ?? "",
              selectedVariant?.price ?? "",
              product?.handle ?? "",
              productPathPrefix
            )
          }
        }}
      />
    </LinkedProductsContainer>
  ) : null
}
