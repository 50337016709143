import React, { useContext, useEffect, useState } from "react"
import { ProductContext } from "contexts/ProductContext"
import { Incrementor } from "components/UI/Incrementor/Incrementor"
import { AddToCartButton } from "components/Products/AddToCartButton"
import { VariantDropup } from "components/Products/VariantDropup"
import { Callouts } from "components/Products/Callouts"
import { FixedCustomizeDrawer } from "components/Products/FixedCustomizeDrawer"
import { useWindowScroll } from "react-use"
import { ReviewStarsWithNumber } from "components/YotpoReviewList/ReviewStarsWithNumber"
import { $IsOpen, ModeType } from "typings/modules"
import { Container, Left, Right, Title } from "./FixedProductBar.styles"
import { useHiddenContentAnim } from "hooks/useHiddenContentAnim"
import { durations } from "styles/animations"
import { useTransition } from "react-spring"
import {
  LargeAndBelow,
  LargerAndUp,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"
import { useMinReviewsNumber } from "hooks/useMinReviewsNumber"

type MobileProps = {
  loading: boolean
}
type Props = MobileProps & {
  title: string
  mode: ModeType
  addToCartVisible: boolean
}

const DesktopProductBarAnim: React.FC<Props & $IsOpen> = ({
  title,
  loading,
  mode,
  $isOpen,
}) => {
  const {
    variantTypes,
    quantity,
    setQuantity,
    review,
    actionRequired,
    actionRequiredText,
    selectedVariant,
  } = useContext(ProductContext)
  const starCount = review?.bottomline?.averageScore || 0
  const reviewCount = review?.bottomline?.totalReview || 0
  const minReviewsNumber = useMinReviewsNumber()
  const showReviews = reviewCount >= minReviewsNumber
  const price = selectedVariant?.price ? parseFloat(selectedVariant.price) : 0

  const containerAnim = useTransition($isOpen, null, {
    from: { transform: "translateY(100%)" },
    enter: { transform: "translateY(0)" },
    leave: { transform: "translateY(100%)" },
    config: { duration: durations.fixedProductBar },
  })

  return (
    <>
      {containerAnim.map(
        ({ item, key, props }) =>
          item && (
            <Container key={key} style={props}>
              <Left>
                <Title>{title}</Title>
                {showReviews && (
                  <ReviewStarsWithNumber
                    reviewCount={reviewCount}
                    starCount={starCount}
                    clickable={false}
                    mode={mode}
                  />
                )}
              </Left>
              <Right>
                <Callouts />
                {variantTypes?.map((optionType, i) => (
                  <VariantDropup
                    key={`var-set-${i}`}
                    optionType={optionType}
                    barOpen={$isOpen}
                  />
                ))}
                {price > 0 && (
                  <Incrementor quantity={quantity} setQuantity={setQuantity} />
                )}
                <AddToCartButton
                  loading={loading}
                  actionRequired={actionRequired}
                  text={actionRequiredText}
                />
              </Right>
            </Container>
          )
      )}
    </>
  )
}

const MobileProductBar: React.FC<MobileProps> = ({ loading }) => {
  const { variants, actionRequired, actionRequiredText } = useContext(
    ProductContext
  )

  const singleVariant = variants.length < 2

  return (
    <Container $singleVariant={singleVariant}>
      <Left $singleVariant={singleVariant}>
        <FixedCustomizeDrawer />
      </Left>
      <Right>
        <AddToCartButton
          loading={loading}
          actionRequired={actionRequired}
          text={actionRequiredText}
          fullWidth={true}
        />
      </Right>
    </Container>
  )
}

export const FixedProductBar: React.FC<Props> = ({ ...props }) => {
  const { y } = useWindowScroll()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(y > window.innerHeight)
  }, [y])

  const anim = useHiddenContentAnim(isOpen, durations.fixedProductBar)

  return (
    <>
      <LargerAndUp>
        {anim.isVisible && (
          <DesktopProductBarAnim $isOpen={isOpen} {...props} />
        )}
      </LargerAndUp>
      <LargeAndBelow>
        {anim.isVisible && !props.addToCartVisible && y > 0 && (
          <MobileProductBar {...props} />
        )}
      </LargeAndBelow>
    </>
  )
}
