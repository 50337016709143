import React, { useContext } from "react"
import styled, { ThemeContext, ThemeProvider } from "styled-components"
import { typography } from "styles"
import { SanityTag } from "typings/graphql"
import {
  $BgColorOpacity,
  $BorderColor,
  $Pill,
  $TextColorOpacity,
} from "typings/modules"
import { Link } from "gatsby"

type Props = { tag: SanityTag }

const TagContainer = styled.div<
  $TextColorOpacity & $BgColorOpacity & $Pill & $BorderColor
>`
  display: inline-block;
  color: ${({ $textColor }) => $textColor?.opacityHex};
  border: ${({ $borderColor }) =>
    $borderColor ? `1px solid ${$borderColor.opacityHex}` : ""};
  border-radius: ${({ $pill }) => ($pill ? "50px" : "")};
  background-color: ${({ $bgColor }) => $bgColor?.opacityHex};
  ${typography.caption};
  padding: 0 12px;
  z-index: 1;
`

export const Tag = (props: Props) => {
  const tag = props.tag
  const themeContext = useContext(ThemeContext)
  const { primaryText, bgColor, pill, borderColor, link } = tag

  const tagContent = (
    <TagContainer
      $textColor={primaryText}
      $bgColor={bgColor}
      $pill={pill}
      $borderColor={borderColor}
    >
      {tag.title}
    </TagContainer>
  )

  return (
    <ThemeProvider theme={{ localTheme: themeContext["dark"] }}>
      {link ? <Link to={link}>{tagContent}</Link> : tagContent}
    </ThemeProvider>
  )
}
