import { siteConstants } from "constants/site"
import { animated } from "react-spring"
import styled from "styled-components"
import { mq, typography, zIndexes } from "styles"
import { $SingleVariant } from "typings/modules"

export const Container = styled(animated.div)<Partial<$SingleVariant>>`
  position: fixed;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.dark?.canvas?.primary?.opacityHex};
  z-index: ${zIndexes.zIndex7FixedFooter};
  height: ${siteConstants.mobileFixedFooterHeight}px;
  padding: 16px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.35);
  ${mq.minWidth("md")} {
    height: ${siteConstants.desktopFixedFooterHeight}px;
  }
  ${mq.minWidth("lg")} {
    grid-template-columns: auto 1fr;
  }
  ${({ $singleVariant }) =>
    $singleVariant &&
    `
    grid-template-columns: 1fr;
  `}
`

export const Left = styled.div<Partial<$SingleVariant>>`
  height: 100%;
  display: grid;
  align-items: center;
  ${mq.maxWidth("lg")} {
    ${({ $singleVariant }) =>
      $singleVariant &&
      `
      display: none;
    `}
  }
`

export const Right = styled.div`
  ${mq.minWidth("lg")} {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    justify-self: flex-end;
  }
`

export const Title = styled.h5`
  ${typography.h5};
  white-space: nowrap;
  color: var(--text-headline-dark);
  margin: 0;
`
